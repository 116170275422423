import { Button, Card, CardActions, CardContent, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { DarkNegativeSendIcon } from '../icons/icons';
import Markdown from '../markdown/markdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    story: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left'
    },
    action: {
      margin: theme.spacing(1),
    },
    centered: {
      maxWidth: '640px',
      margin: 'auto'
    },
    overflow: {
      overflowY: 'auto',
      height: '100px'
    },
    confusion: {
      fontStyle: 'italic',
      color: 'red'
    },
    emphasis: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

const LANGUAGES: {[key:string]:any} = {
  "nocode": {
    display: "No Code",
    path: ""
  },
  "python": {
    display: "Python",
    path: "python"
  },
  "julia": {
    display: "Julia",
    path: "julia"
  },
  "typescript": {
    display: "TypeScript",
    path: "javascript"
  }
}

const FORMATS: {[key:string]:any} = {
  "nocode": {
    display: "Point and Click"
  },
  "quick": {
    display: "Quick Read"
  },
  "zeroinstall": {
    display: "Zero Install"
  },
  "developer": {
    display: "Developer"
  }
}

const APIS: {[key:string]:any} = {
  "app": {
    display: "NavAbility App",
    path: "navability-app"
  },
  "sdk": {
    display: "NavAbility SDK",
    path: "navability-sdk"
  },
  "dfg": {
    display: "NavAbility DFG",
    path: "navability-dfg"
  },
  "caesar": {
    display: "Caesar.jl",
    path: "caesar-api"
  }
}

const COMING_SOON_PATH = '/get-started/tutorials/coming-soon'

const TutorialView = ({ tutorial }: { tutorial: any }) => {
  const classes = useStyles()
  const { rawMarkdownBody } = tutorial

  const [language, setLanguage] = useState("python")
  const [format, setFormat] = useState("zeroinstall")
  const [api, setApi] = useState("sdk")
  const [formatOptions, setFormatOptions] = useState(["quick","zeroinstall","developer"])
  const [apiOptions, setApiOptions] = useState(["sdk"])

  // const languageOptions = ["nocode","python","julia","typescript"]
  const languageOptions = ["python","julia"]

  const handleLanguageChange = (event:any) => {
    if(event.target.value === "nocode") {
      setFormatOptions(["nocode"])
      setFormat("nocode")
      setApiOptions(["app"])
      setApi("app")
    } else if(event.target.value === "julia") {
      setFormatOptions(["quick","zeroinstall","developer"])
      setFormat("zeroinstall")
      setApiOptions(["sdk", "caesar", "dfg"])
      setApi("caesar")
    } else {
      setFormatOptions(["quick","zeroinstall","developer"])
      setFormat("zeroinstall")
      setApiOptions(["sdk"])
      setApi("sdk")
    }
    setLanguage(event.target.value)
  }

  const generateHref = () => {
    return encodeURI(`/get-started/tutorials/${LANGUAGES[language].path}/${APIS[api].path}/${tutorial.frontmatter.slug}`)
  }

  const navigateBlank = (href:string) => {
    if(href === COMING_SOON_PATH || href === '') {
      navigate(COMING_SOON_PATH)
    } 
    else if(typeof window !== `undefined`) {
      window.open(href, '_blank')
    }
  }

  const handleBegin = () => {
    if(language === "nocode") {
      navigate(COMING_SOON_PATH)
    }
    
    else if(language === "python") {
      if(format === "nocode") {
        navigate(COMING_SOON_PATH)
      } else if (format === "quick") {
        navigate((tutorial.frontmatter.pythonSdkNotebook && generateHref()) || COMING_SOON_PATH)
      } else if (format === "zeroinstall") {
        if(api === "sdk") {
          navigateBlank(tutorial.frontmatter.pythonSdkNotebook || COMING_SOON_PATH )
        }
      } else if (format === "developer") {
        navigate((tutorial.frontmatter.pythonSdkNotebook && generateHref()) || COMING_SOON_PATH)
      }
    }

    else if(language === "julia") {
      if(format === "nocode") {
        navigate(COMING_SOON_PATH)
      } else if (format === "quick") {
        if(api === "sdk") {
          navigate((tutorial.frontmatter.juliaSdkNotebook && generateHref()) || COMING_SOON_PATH)
        } else if(api === "caesar") {
          navigate((tutorial.frontmatter.juliaCaesarNotebook && generateHref()) || COMING_SOON_PATH)
        } else if(api === "dfg") {
          navigate((tutorial.frontmatter.juliaDfgNotebook && generateHref()) || COMING_SOON_PATH)
        }
      } else if (format === "zeroinstall") {
        if(api === "sdk") {
          navigateBlank(tutorial.frontmatter.juliaSdkNotebook || COMING_SOON_PATH )
        } else if(api === "caesar") {
          navigateBlank(tutorial.frontmatter.juliaCaesarNotebook || COMING_SOON_PATH )
        } else if(api === "dfg") {
          navigateBlank(tutorial.frontmatter.juliaDfgNotebook || COMING_SOON_PATH )
        }
      } else if (format === "developer") {
        if(api === "sdk") {
          navigate((tutorial.frontmatter.juliaSdkNotebook && generateHref()) || COMING_SOON_PATH)
        } else if(api === "caesar") {
          navigate((tutorial.frontmatter.juliaCaesarNotebook && generateHref()) || COMING_SOON_PATH)
        } else if(api === "dfg") {
          navigate((tutorial.frontmatter.juliaDfgNotebook && generateHref()) || COMING_SOON_PATH)
        }
      }
    }

    else if(language === "typescript") {
      navigate(COMING_SOON_PATH)
    }

    else {
      navigate(COMING_SOON_PATH)
    }
  }
    
  return (
    <>
      <Card className={classes.story}>
        <CardContent>
          <Markdown children={rawMarkdownBody} />
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Choose your own adventure
          </Typography>
          <Typography gutterBottom variant="body1" component="p">
            Or clone the github repo here: <Link target="_blank" color="primary" underline="none" variant="body2" href="https://github.com/NavAbility/BinderNotebooks.git">https://github.com/NavAbility/BinderNotebooks.git</Link>
          </Typography>
        </CardContent>
        <CardActions>
          <FormControl className={classes.formControl}>
            <InputLabel id="language-select-id">Language</InputLabel>
            <Select
              labelId="language-select-id"
              id="language-select"
              value={language}
              onChange={handleLanguageChange}
            >
              {languageOptions.map((option:string) => (<MenuItem key={option} value={option}>{LANGUAGES[option].display}</MenuItem>))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="format-select-id">Format</InputLabel>
            <Select
              labelId="format-select-id"
              id="format-select"
              value={format}
              onChange={(event: any) => setFormat(event.target.value)}
            >
              {formatOptions.map((option:string) => (<MenuItem key={option} value={option}>{FORMATS[option].display}</MenuItem>))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="api-select">Interface</InputLabel>
            <Select
              value={api}
              onChange={(event: any) => setApi(event.target.value)}
              inputProps={{
                name: 'api',
                id: 'api-select',
              }}
            >
              {apiOptions.map((option:string) => (<MenuItem key={option} value={option}>{APIS[option].display}</MenuItem>))}
            </Select>
            </FormControl>
            <Button className={classes.centered} variant="contained" color="secondary"
              startIcon={<DarkNegativeSendIcon fontSize="large" />}
              onClick={handleBegin}>
              Begin
            </Button>
        </CardActions>
        <CardContent>
          <Typography gutterBottom variant="h3" component="h3">
            Your quest
          </Typography>
          {language === "nocode" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              No Code
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              This is not really a language but an interactive journey. You can use all the features of the NavAbility App to learn about navigation.
            </Typography>
          </div>}
          {language === "python" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Python
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              An excellent programming language. Very popular in data science, machine learning and robotics!
            </Typography>
          </div>}
          {language === "julia" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Julia
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              The next generation language for science. Caesar.jl is written in Julia and the open core community works in Julia.
            </Typography>
          </div>}
          {language === "typescript" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              TypeScript
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              The language for the web! The NavAbility App is built in TypeScript and our SDK fully supports NodeJS.
            </Typography>
          </div>}
          {format === "nocode" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Point and Click
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              No need to leave the App. We will guide you along demonstrating the features of the app as well as teaching you the same lessons you would learn through coding.
            </Typography>
          </div>}
          {format === "zeroinstall" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Zero Install
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Jupyter notebooks running in a cloud environment. Click begin to jump over to the Binder environment!
            </Typography>
          </div>}
          {format === "developer" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Developer
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Step-by-step instructions for running the tutorials in your local environment.
              All the results are pre-rendered so you can compare notes.
            </Typography>
          </div>}
          {format === "quick" && 
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Quick Read Through
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Step-by-step instructions that have results. You can quickly read through and run the tutorials at later time.
            </Typography>
          </div>}
          {api === "app" &&
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              NavAbility App
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              NavAbility's "console" of sorts. This fully featured app provides all the tools you need to interact with your navigation map without having to write any lines of code.
            </Typography>
          </div>}
          {api === "sdk" &&
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              NavAbility SDK
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              NavAbility's fully featured, multi-language implementation of Caesar.jl. Your example will run in NavAbility Cloud.
              The SDK versions of the tutorials are coming soon.
            </Typography>
          </div>}
          {api === "caesar" &&
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              Caesar.jl
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Work through using only the open source libraries built by the team who founded NavAbility! 
              Your example will run where the code is running.
            </Typography>
          </div>}
          {api === "dfg" &&
          <div>
            <Typography gutterBottom variant="h4" component="h4">
              NavAbility DFG
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Integrate NavAbility Cloud into the Caesar.jl open source ecosystem.
              This implementation of DistributedFactorGraphs enables open sources users to still leverage our cloud.
            </Typography>
          </div>}
        </CardContent>
      </Card>
    </>
  )
}

export default TutorialView