import { Box } from '@mui/material';
import { graphql } from "gatsby";
import React from "react";
import SEO from "../../../components/common/seo";
import TutorialView from "../../../components/tutorials/tutorial-view";

export default function Template({data}) {
  const { tutorial } = data
  return (
    <Box style={{ height: '95vh', overflowY: 'auto' }}>
      <Box>
      <SEO title="NavAbility Tutorials" />
      <TutorialView tutorial={tutorial} />
      </Box>
    </Box>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    tutorial: markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      frontmatter {
        title
        slug
        pythonSdkNotebook
        juliaSdkNotebook
        juliaCaesarNotebook
        juliaDfgNotebook
      }
    }
  }
`